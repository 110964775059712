.mat-mdc-list-item:not(.mat-mdc-list-item-interactive) {
  cursor: pointer;
}

.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}

.list-item-text {
  white-space: normal;
  font-size: 25px;
  font-weight: 300;
}
