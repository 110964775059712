.info-card {
  width: 100%;
  margin: 1.2rem 0;

  &__title {
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0 0 1.5rem !important;
    text-align: center;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__item {
    display: flex;
  }

  &__label {
    padding-right: 0.5rem;
    flex: 0 1 auto;
    font-size: 1rem;
    font-weight: 700;
  }

  &__value {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: keep-all;
    line-break: strict;
    hyphens: none;
    flex: 0 1 auto;
    max-width: 70%;

    .block-text {
      display: block;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
