@import 'abstract/variables';

.mat-mdc-snack-bar-container {
  color: $white;
  font-size: 16px;
  background: $light-blue;
  box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
}

.mat-mdc-snack-bar-action button {
  max-height: 36px;
  min-width: 0;
  border: none;
  background: transparent;
  color: $black-font-color;

  &:hover {
    opacity: 0.8;
  }
}

.warning {
  background: #f44336;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  --mat-text-button-state-layer-color: none !important;
  --mat-text-button-ripple-color: none !important;
}
