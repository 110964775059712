@import 'abstract/variables';

.mdc-tab__text-label,
.mat-mdc-tab-link {
  letter-spacing: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px !important;
  color: $black-font-color !important;
  opacity: 1 !important;
}

.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $light-blue;
}

.mat-mdc-tab-header, .mat-mdc-tab-nav-bar {
  border-bottom: 1px solid $gray;
}

.mat-mdc-tab-link .mdc-tab-indicator__content--underline {
  border-color: $light-blue !important;
}

.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  flex-grow: 0 !important;
}
