@import 'abstract/variables';

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-layout {
  align-items: center !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-background, .mat-checkbox-frame {
  border-radius: $check-box-border-radius !important;
}

.mat-mdc-checkbox {
  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    background-color: $light-blue !important;
    border: none;
    color: $white;
    border-radius: 3px !important;
  }

  .mdc-checkbox__background {
    border-color: $gray !important;
    border-width: 2px !important;
    width: 24px !important;
    height: 24px !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-inner-container {
  height: 24px !important;
  width: 24px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-layout .mat-checkbox-label {
  line-height: 17px;
  font-size: 14px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-mdc-checkbox-disabled .mat-checkbox-label {
  color: rgb(0 0 0 / 0.54);
}


.mat-mdc-checkbox {
  .mdc-label {
    line-height: 17px !important;
    font-size: 14px !important;
  }
}

.mat-mdc-checkbox .mdc-form-field {
  align-items: baseline;
  display: flex;
}

.mdc-checkbox {
  margin-right: 8px !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  top: 2px !important;
  left: 2px !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 3px !important;
}
