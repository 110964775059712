html, body {
  height: 100%;
}

body {
  margin: 0;
}

body.mat-typography, .mat-typography, .mat-body, .mat-body-2 {
  font: 400 14px/20px;
}

.mat-typography h1, h2, h3, h4, h5, p {
  margin: 0;
}
