@import 'abstract/variables';

.logo {
  margin-bottom: 60px;

  img {
    width: 200px;
  }

  @media (min-width: $lg) {
    img {
      width: 370px;
      margin-top: -20px;
    }
  }

  @media (min-width: $xxl) {
    img {
      margin-top: 0;
    }
  }
}
