@import 'abstract/variables';

.table-wrap {
  position: relative;
  padding-top: 10px;
}

.table-wrap-overflow {
  width: 100%;
  overflow: auto;
}

.table-row {
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.35, 0, 0.25, 1);

  &:hover {
    background-color: $sp-gray-bg;
  }
}

.cell-text {
  margin: 0 !important;
}

.ceil-edit {
  padding: 0 18px;
}

.ceil-action {
  box-sizing: content-box;
  width: 40px;
  margin: 0 !important;
  text-align: center !important;
}

.ceil-status {
  width: fit-content;
  box-sizing: content-box;
  margin: 0 !important;
  text-align: center !important;
}

.typography-table {
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  background: var(--dark-purple);
}

.cell-d-f-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-mdc-table {
  background-color: $white !important;
}

.mat-mdc-table-sticky, .mat-table tbody, .mat-table tfoot, .mat-table thead, [mat-footer-row], [mat-header-row], [mat-row], mat-footer-row, mat-header-row, mat-row {
  background: inherit;
}

mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
  @extend .typography-table;

  padding-left: 0 !important;
}

mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
  padding-right: 0 !important;
}

.no-padding-table {
  .mat-mdc-header-cell {
    @extend .typography-table;
    @extend .cell-d-f-c;
  }

  mat-header-row, mat-row, mat-footer-row {
    min-height: 32px;
    align-items: stretch !important;
  }

  mat-cell {
    @extend .cell-d-f-c;

    border-right: 1px solid #e0e0e0;
  }

  .mat-mdc-table-sticky:last-child {
    border-left: 1px solid #e0e0e0;
  }
}

.table-cell-padding {
  .mat-mdc-cell {
    padding-top: 4px;
  }

  .mat-mdc-cell, .mat-mdc-header-cell {
    padding-right: 4px;
    padding-left: 4px;
    min-width: 80px;

    &.header-for-btn {
      text-align: center;
    }
  }
}

td.mat-mdc-cell {
  font-size: 14px;
  line-height: 17px;
  border-bottom-color: $table-border-light-gray;
}

th.mat-mdc-header-cell {
  font-size: 16px !important;
  line-height: 17px !important;
}

.editable-cell {
  th.mat-mdc-header-cell:last-of-type, td.mat-mdc-cell:last-of-type, td.mat-mdc-footer-cell:last-of-type {
    text-align: end;
  }
}

@media (min-width: 768px) {
  .patient-table {
    width: 57vw !important;
    max-width: 75vw !important;
    max-height: 88vh !important;
  }
}

.table {
  &__wrapper {
    position: relative;
  }

  &__smile {
    display: inline-block;
  }

  &__no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 100%;
    text-align: center;
    margin: 0;
    background: rgb(0 0 0 / 0.12);
    color: var(--black);
    font-weight: bold;

    &.hide {
      display: none;
    }
  }

  &__info-block {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 10;
  }

  &__info-title {
    font: 500 20px/32px;
    letter-spacing: normal;
    margin: 0 0 16px;

    &.sad-smile::after {
      display: inline-block;
    }
  }

  &__info-text {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    margin: 0 0 12px;
  }

  p {
    margin: 0;
  }
}

tr.mat-mdc-header-row {
  height: 50px !important;
  background: $light-blue;
  color: $white;
}

tr.mat-mdc-row {
  height: 50px !important;
}

.mat-mdc-paginator-page-size-label {
  opacity: 0.5;
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  color: $sp-gray;
}

.mat-mdc-paginator-page-size .mat-select-trigger {
  font-family: Rubik, sans-serif;
  font-size: 16px !important;
  line-height: 19px;
  color: $black-font-color;
}

.mat-mdc-paginator-range-label {
  font-size: 14px;
  line-height: 17px;
}

.ceil-word-break {
  word-break: break-word;
  max-width: 150px;
}

.mdc-data-table__cell.mat-mdc-table-sticky {
  background-color: inherit;
}

.editable-cell td.mat-mdc-cell:first-of-type {
  padding-left: 24px;
}

.editable-cell td.mat-mdc-cell:last-of-type {
  padding-right: 24px;
}

.editable-cell th.mat-mdc-header-cell:last-of-type {
  padding-right: 24px;
}

.editable-cell th.mat-mdc-header-cell:first-of-type {
  padding-left: 24px;
}

