$green: #58901a;
$light-green: #a1c329;
$blue: #0081c9;
$light-purple: #7561aa;
$light-gray: #e6e7e8;
$white: #ffffff;
$red: #f44336;
$dark-gray: #5c5c5c;
$black: #000000;
$warm: #f5bd41;
$gray-ripple-line: #8e8e8e;

// redesign
$bg-gray: #D3D3D3;
$black-font-color: #212125;
$light-blue: #0AABB8;
$middle-blue: #45A0D3;
$hov-light-blue: #16C9D8;
$extra-light-blue: #B2D6DC;
$light-blue-bg: #9BCCDC;
$extra-light-blue-bg: #D4EAEC;
$gray: #8E8E8E;
$middle-dark--gray: #A3A8A6;
$middle-gray: #ABABAB;
$extra-light-gray: #F6F5F5;
$table-border-light-gray: #D5D5D5;
$light-gray-300: #F0F0F0;
$linear-blue: linear-gradient(122.96deg, #2A9BD5 -2.07%, #529581 100%);
$light-pink: #ffcdd2;
$middle-pink: #ff80ab;
$middle-purple: #7660AB;
$dark-purple: #69579B;
$extra-light-purple: #C7B0CE;
$middle-green: #75B377;
$scrollbar-bg: #D8EBEE;

//new restyling
$sp-gray: #B8B8B9;
$sp-gray-disabled: #E4E4E5;
$sp-gray-bg: #F4F4F4;
$sp-dark-light-blue: #0D979F;
$sp-pink-200: #A59AC3;
$sp-stripe-error: #df1b41;

// shadow
$primary-shadow: -5px -5px 7px rgb(255 255 255 / 0.8), 5px 5px 7px #C1C1D0;

// border
$border-blue: 2px solid $light-blue;
$border-gray: 2px solid $sp-gray-disabled;
$primary-border-radius: 16px;
$check-box-border-radius: 3px;
$btn-common-border-radius: 8px;

// animation
$btn-hover-animation: 0.3s cubic-bezier(0.35, 0, 0.25, 1);

// screen
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$exl: 1440px;
$xxl: 1920px;

//app colors
:root {
  --font-family: 'Rubik', sans-serif;
  --font-weight: 400;
  --font-size-base: 1rem;
  --font-size-h1: 5.4rem;
  --font-size-h2: 4.4rem;
  --font-size-h3: 3.4rem;
  --font-size-h4: 2.4rem;
  --font-size-h5: 2rem;
  --font-size-reduced: 1.2rem;
  --font-size-increased: 2rem;
  --font-size-error-text: 1.2rem;
  --green: #{$green};
  --light-green: #{$light-green};
  --blue: #{$blue};
  --light-purple: #{$light-purple};
  --gray: #{darken($light-gray, 10%)};
  --light-gray: #{$light-gray};
  --white: #{$white};
  --red: #{$red};
  --dark-gray: #{$dark-gray};

  /* ----- Redesign ----- */
  --linear-blue: #{$linear-blue};
  --gray-middle-dark: #{$sp-gray};

  /* ----- UNIQUE COLORS ----- */
  --loginpage-color: #F2F6FC;
  --custom-line-bar: #f6f6f6;
  --custom-line-progress: #2665ee;

  /* ----- Progress BAR ----- */
  --progress-bar-color: var(--green);
  --progress-bar-background-color: var(--light-gray);

  /* ----- SPACING ----- */
  --vertical-padding: 3.2rem;
  --horizontal-padding: 3.2rem;
  --header-height-base: 6.4rem;
  --sidenav-width-initial: 17rem;
  --mobile-preview-width: 34.4rem;

  /* ---- VISUAL STYLES -- */
  --border-radius: 0.4rem;
  --border-radius-lg: 0.8rem;
  --fab-btn: 5.6rem;
  --thumbnail-size: 6.4rem;
  --progressbar-min: 6.4rem;
  --progressbar-max: 12.8rem;
  --modal-header-height: 6.4rem;

  /* ----- SCREEN DIMENSIONS ----- */
  --mobile-width: 576px;
  --tablet-width: 768px;
  --desktop-width: 992px;
  --lg-width: 1400px;

  /* ----- HIGHLIGHTING ----- */
  --highlight-color: #eaeeb1;

  /* ----- LIST ITEM ----- */
  --list-item-background: #{lighten($light-purple, 40%)};
  --list-item-hover-background: #{lighten($light-purple, 35%)};

  /* ----- NAV ----- */
  --nav-background: #{$light-blue};
  --nav-btn-hover-active-background: #{$hov-light-blue};
  --nav-btn-hover-background: #{$light-blue};
  --nav-btn-active-background: #{$light-blue};
}
