@import 'abstract/variables';

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin only_safari {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      @content;
    }
  }
}

@mixin scrollbar {
  &::-webkit-scrollbar, .chat__rich-textarea::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb, .chat__rich-textarea::-webkit-scrollbar-thumb {
    background: #CFD8DC;
  }

  &::-webkit-scrollbar-thumb:hover, .chat__rich-textarea::-webkit-scrollbar-thumb:hover {
    background: #B0BEC5;
  }
}

@mixin btn(
  $color,
  $border: none,
  $hover-bg: $sp-dark-light-blue,
  $text-color: var(--white),
  $hover-text-color: var(--white),
  $disabled-border: none,
  $disabled-text-color: var(--white),
) {
  background-color: $color;
  border: $border;
  border-radius: $btn-common-border-radius !important;
  color: $text-color !important;
  cursor: pointer;
  flex-shrink: 0;
  font-family: var(--font-family);
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 10px 40px !important;
  text-transform: uppercase;
  transition: 0.3s cubic-bezier(0.35, 0, 0.25, 1);

  &:hover {
    color: $hover-text-color !important;
    background-color: $hover-bg;
    transition: $btn-hover-animation;
  }

  &:disabled {
    border: $disabled-border;
    background-color: var(--gray-middle-dark);
    color: var(--white) !important;
    cursor: default;
  }
}
