//Rubik fonts

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/rubik/Rubik-Light.ttf');
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/rubik/Rubik-Regular.ttf');
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/rubik/Rubik-Medium.ttf');
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/rubik/Rubik-Bold.ttf');
}
