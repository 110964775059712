@import 'abstract/variables';

/* slightly transparent fallback */
.overlay-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgb(255 255 255 / 0.9);
  border-radius: $btn-common-border-radius;
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: blur(2px)) or (backdrop-filter: blur(2px))) {
  .overlay-container {
    background-color: rgb(255 255 255 / 0.5);
    backdrop-filter: blur(2px);
  }
}
