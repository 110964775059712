@import 'abstract/variables';

.mat-mdc-menu-content {
  background: $sp-gray-bg;
  height: 100%;
}

.mat-mdc-menu-panel {
  max-width: none !important;
  min-height: auto !important;
  border-radius: 8px !important;
}

button.mat-mdc-menu-item {
  background: transparent;
}

.mat-mdc-menu-item:hover:not([disabled]) {
  color: $light-blue;
  background-color: $sp-gray-disabled !important;
}

.mat-mdc-menu-item .mat-icon {
  &:hover {
    color: $black-font-color;
  }
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex;
}

.mat-mdc-menu-item {
  min-height: 40px !important;
}

.mat-mdc-menu-item-text {
  font-size: 18px !important;
}
