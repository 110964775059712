.form-page {
  @extend .container;

  font-size: 16px;
  line-height: 1.125;

  &__title {
    font-weight: 700;
    margin: 0 !important;
    padding: 0.8rem 0;
  }

  * > .mat-mdc-card {
    margin-bottom: 1.4rem;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-label {
    white-space: pre-wrap;
  }

  textarea.mat-mdc-input-element {
    line-height: 0.92;
    padding: 0;
    margin: 0;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;

  &__title {
    font-weight: 700 !important;
    line-height: 1.4 !important;
    padding-right: 3rem;
    margin: 0.5rem 0;
    max-width: 700px;
  }

  &__actions {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 0 0 1.5rem;

    & > * {
      margin: 0.25rem;
    }
  }

  @media (max-width: 544px) {
    &__actions {
      margin-right: 0;
      flex: 1;

      & > * {
        flex: 1;
      }
    }
  }
}
