@import 'abstract/variables';

.template-filters {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: calc(var(--horizontal-padding) * -0.25);
  margin-right: calc(var(--horizontal-padding) * -0.25);

  > * {
    padding: 0 calc(var(--horizontal-padding) * 0.25);
  }

  .range-text {
    padding: 1rem 0;
    width: 120px;
    margin: 0;
  }

  .range-picker-wrap {
    max-width: 200px;
  }

  .range-date {
    margin-bottom: 1rem;

    @media (min-width: $md) {
      width: 100%;
      flex-basis: 50%;
      margin-bottom: 0;
    }
  }

  .activity-range-date {
    margin-bottom: 1rem;

    @media (min-width: $md) {
      width: 100%;
      flex-basis: 100%;
      margin-bottom: 0;
    }
  }

  .admin-users-status {
    margin-bottom: 1rem;

    @media (min-width: $md) {
      width: 100%;
      flex-basis: 28%;
      margin-bottom: 0;
    }
  }

  .action-wrap {
    //margin-left: 1rem;
  }

  .additional-tools {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
  }
}
