@import 'abstract/variables';

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: $light-pink;
}

.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__bar-inner {
  border-color: $middle-pink;
}

.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: $light-blue-bg;
}

.mat-mdc-progress-bar .mdc-linear-progress__bar-inner {
  border-color: $light-blue;
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: $light-pink;
}

.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__bar-inner {
  border-color: $red;
}
