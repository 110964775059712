@import 'abstract/variables';

$margin-20px: 20px;
$margin-30px: 30px;
$margin-40px: 40px;

.left-0 {
  left: 0;
}

.text-ellipsis {
  line-height: initial;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.text-error {
  color: $red !important;
}

.text-white {
  color: $white;
}

.min-w-40 {
  min-width: 40px !important;
}

.min-w-70 {
  min-width: 70px !important;
}

.asterisk {
  color: $red;
}

.mb-20 {
  margin-bottom: $margin-20px;
}

.mb-20-i {
  margin-bottom: $margin-20px !important;
}

.mb-30 {
  margin-bottom: $margin-30px;
}

.mb-40 {
  margin-bottom: $margin-40px;
}

.common-search-wrap {
  .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
    background-color: var(--white) !important;
    border: 2px solid $sp-gray !important;
    border-radius: $btn-common-border-radius !important;
    box-sizing: border-box !important;
    box-shadow: none !important;
  }
}

.text-transform-initial {
  text-transform: initial;
}

.max-w-100 {
  max-width: 100%;
}

.transaction-failed-status,
.transaction-success-status,
.transaction-pending-status {
  border-radius: 20px;
  padding: 2px 10px;
  background: $white;
  min-width: auto;
  width: fit-content;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}

.transaction-success-status {
  border: 2px solid $middle-green;

  span {
    white-space: nowrap;
    color: $middle-green;

    &::before {
      background-color: $middle-green;
    }
  }
}

.transaction-pending-status {
  border: 2px solid $warm;

  span {
    white-space: nowrap;
    color: $warm;

    &::before {
      background-color: $warm;
    }
  }
}

.transaction-failed-status {
  border: 2px solid $red;

  span {
    white-space: nowrap;
    color: $red;

    &::before {
      background-color: $red;
    }
  }
}
