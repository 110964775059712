@import 'abstract/variables';

.mat-mdc-dialog-content {
  max-height: 70vh;
}

.mat-mdc-dialog-container {
  box-sizing: border-box;
  border: 2px solid $sp-gray;
  border-radius: $btn-common-border-radius;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.25);
  background: rgb(255, 255, 255);
  padding: 50px 40px !important;

  & > * {
    position: relative;
    display: block;
    height: 100%;
  }

  .new-patient-dialog.mat-mdc-dialog-content {
    max-height: 67vh;
  }
}

.new-patient-dialog {
  .mat-mdc-dialog-content {
    max-height: 70vh;
  }
}

.patient-popup-card {
  .mat-mdc-dialog-content {
    max-height: 100%;
  }
}

.announcement-popup {
  .mat-mdc-dialog-container {
    max-width: 80vw;
    max-height: 92vh;
    width: 80vw;
    height: 92vh;
  }
}

.change-log-popup {
  .mat-mdc-dialog-container {
    max-width: 80vw;
    max-height: 92vh;
    width: 80vw;
  }
}

.knowledge-base-category-popup {
  .mat-mdc-dialog-container {
    max-width: 60vw;
    max-height: 86vh;
    width: 60vw;
    min-height: 600px;
    height: auto;
  }
}

.download-patient-form-popup {
  .mat-mdc-dialog-container {
    max-width: 60vw;
  }
}

.pharmacy-view-note {
  .mat-mdc-dialog-container {
    width: 55vw;
  }
}

.order-info,
.dosing-instructions {
  .mat-mdc-dialog-container {
    max-width: 1100px;
  }
}

.prescribe-dialog {
  .mat-mdc-dialog-container {
    max-width: 1300px;
  }
}

.pharmacy-detail-popup {
  .mat-mdc-dialog-container {
    width: 500px;
    min-height: 500px;
  }
}

.request-new-pharmacy-popup {
  .mat-mdc-dialog-container {
    width: 500px;
    min-height: 480px;
  }
}

.add-pharmacy-popup {
  .mat-mdc-dialog-container {
    width: 600px;
    min-height: 300px;
  }
}


.pharmacy-request-status-popup {
  .mat-mdc-dialog-container {
    width: 500px;
  }
}

.close {
  z-index: 2;
  position: absolute;
  top: -18px;
  right: -7px;
  height: 24px;
  width: 24px;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  background: url('/assets/images/close.svg') no-repeat 50% 50%;
  box-sizing: content-box;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.popup-title {
  margin-right: 1.5rem;
}

/** IE 11 fix for add new patient */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  sp-new-patient-popup, sp-add-new-labs-popup, sp-manage-practice, sp-patent-popup, sp-manage-provider {
    width: 50vw;
    display: block;
  }

  @media (min-width: 768px) {
    sp-new-patient-popup {
      width: 633px;
      display: block;
    }

    sp-add-new-labs-popup {
      width: 860px;
      display: block;
    }

    sp-manage-practice {
      width: 450px;
      display: block;
    }

    sp-patent-popup {
      width: 855px;
      display: block;
    }

    sp-manage-provider {
      width: 912px;
      display: block;
    }
  }

  sp-manage-provider sp-provider-form {
    width: 100%;
    display: block;
  }

  sp-patient-detail {
    sp-new-patient-popup, {
      width: auto;
      display: block;
    }
  }
}

sp-site-note-list-popup {
  width: 633px;
  display: block;
}

.prescribe-dialog {
  max-width: 90vw !important;
}

.sticky-note {
  .mat-mdc-dialog-container {
    border: none;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: none !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  overflow: initial !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 15px !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  font-size: 30px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
}

.mat-mdc-dialog-container .mdc-dialog__container {
  min-height: auto !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}
