@import 'abstract/variables';

.mat-mdc-chip.mat-mdc-standard-chip {
  margin: 4px;
  border: 1px solid $light-blue;
  box-sizing: border-box;
  border-radius: 40px;

  padding: 7px 8px 7px 12px;
}

input.mat-mdc-chip-input {
  width: 100%;
  margin: 4px;
  flex: none !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  margin-left: 0 !important;
}

.mdc-evolution-chip__text-label {
  color: $black-font-color !important;
}
