@import 'abstract/variables';

.auth-form {
  margin: 0 auto;
  max-width: 400px;
}

.auth-action {
  margin-bottom: 30px;
}

.auth-title {
  color: $white !important;
  font-size: 40px !important;
  font-weight: 300 !important;
  line-height: 71px !important;
  margin-bottom: 20px !important;
  margin-top: -15px !important;

  @media (min-width: $lg) {
    font-size: 60px !important;
  }
}

.auth-sub-title {
  color: $black-font-color;
  font-size: 22px !important;
  font-weight: 700 !important;
  line-height: 43px !important;
  margin-bottom: 40px !important;
  text-transform: uppercase;

  @media (min-width: $md) {
    font-size: 36px !important;
  }
}

.auth-m-b {
  margin-bottom: 20px;
}
