@import 'abstract/variables';

.mat-icon.mat-warn {
  color: $red;
}

.mat-icon.mat-primary {
  color: $light-blue;
}

.mat-icon {
  position: relative;
  color: $black-font-color;

  &:hover {
    color: $light-blue;
  }
}

.mat-badge-content {
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  border-radius: 50%;
}

.mat-mdc-button>.mat-icon {
  margin-right: 0 !important;
}
