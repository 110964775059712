@import 'abstract/variables';

.mdc-switch {
  width: 70px !important;
}

.mdc-switch:disabled {
  color: $sp-gray-disabled;
}

.mdc-switch .mdc-switch__handle {
  width: 35px !important;
  height: 20px;
  left: 2px;
}

.mdc-switch:disabled .mdc-switch__shadow,
.mdc-switch:enabled .mdc-switch__shadow {
  background-color: $white;
}

.mdc-switch__icons {
  display: none;
}

.mdc-switch:disabled .mdc-switch__track::before,
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background-color: $sp-gray !important;
}

.mdc-switch:disabled .mdc-switch__track::after,
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background-color: $light-blue !important;
}

.mdc-switch .mdc-switch__track {
  min-width: 70px !important;
  height: 24px !important;
  border-radius: 12px !important;
}

.mdc-switch .mdc-switch__handle-track {
  width: calc(100% - 39px) !important;
}

.mdc-switch:disabled .mdc-switch__track {
  opacity: 1 !important;
}

//.mat-mdc-slide-toggle .mdc-label {
//  color: $black-font-color;
//}
