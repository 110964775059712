@import 'abstract/variables';

.mat-mdc-paginator .mat-mdc-select {
  padding: 11px 8px;
  color: $black-font-color;
  border-radius: 10px;
  border: 1px solid $sp-gray;

  .mat-mdc-select-value-text {
    font-size: 16px;
  }
}

.mat-mdc-paginator {
  margin-top: 20px;

  .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-width: 0;
    color: $sp-gray;
  }

  .mat-mdc-select-arrow {
    color: $sp-gray !important;
  }
}

.mat-mdc-paginator-page-size-select {
  margin-bottom: 0 !important;
  width: 100px !important;
}

.mat-mdc-paginator-range-label {
  margin: 0 24px 0 0;
}

.mat-mdc-paginator-container {
  .mat-mdc-icon-button.mat-mdc-button-disabled {
    border: 2px solid $sp-gray;
  }
}



