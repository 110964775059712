@import 'abstract/variables';

.mat-datepicker-content-container,
.mat-datepicker-content {
  background-color: $sp-gray-bg;
  border-radius: $btn-common-border-radius !important;
}

.mat-calendar-period-button {
  background: $white !important;
}

.mat-datepicker-content .mat-calendar-previous-button, .mat-datepicker-content .mat-calendar-next-button {
  background: $white !important;
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;
  border-radius: 50%;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border: 1.5px solid $light-blue !important;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected), .mat-form-field-disabled .mat-date-range-input-separator {
  color: $sp-gray !important;
}

.mat-calendar-body-selected {
  background-color: $light-blue !important;
  color: $white !important;
}

