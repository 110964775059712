@import 'abstract/variables';

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 12px;
  background: $sp-gray;
  color: $black-font-color;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px !important;
}
