@import 'abstract/variables';

.mat-mdc-card {
  border: none !important;
  padding: 40px !important;
  background: $white !important;
  box-shadow: none !important;
  border-radius: $btn-common-border-radius !important;
  border: $border-gray !important;
}
