@import 'abstract/variables';

.p-relative {
  position: relative;
}

.page-title-intends {
  margin-bottom: 1.5rem !important;
}

.page-title {
  font-weight: 700 !important;
  line-height: 1.4 !important;
}

.margin-l-1 {
  margin-left: 1rem;
}

.margin-b-2 {
  margin-bottom: 2rem;
}

.margin-t-1 {
  margin-top: 1rem;
}

.padding-top-15 {
  padding-top: 15px;
}

.link-text {
  color: inherit;
}

.overflow-hidden {
  overflow: hidden !important;
}

mat-form-field {
  width: 100%;
}

.overflow-visible {
  overflow: visible;
}

.pointer {
  cursor: pointer;
}

.wrap-preloader {
  min-height: 400px;
  position: relative;
}

.col-no-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.overlay-edit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 4;
}

.text-decoration-underline {
  text-decoration: underline;
}

.note-text {
  color: var(--red);
  font-weight: 700;
}

.title-bold {
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.consult-title {
  font-weight: 700 !important;
  line-height: 1.4 !important;
  margin-bottom: 1.5rem !important;

  &-color {
    color: $black-font-color;
    font-weight: 500;
    font-stretch: normal;
    font-size: 20px;
    line-height: 1.4;
    margin: 2rem 0 1.5rem;
  }
}

.resize-none {
  resize: none !important;
}

/* ie11 fix */

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-flex {
  align-items: center !important;
}

.cdk-overlay-container {
  width: 100vw;
  height: 100vh;
}

.info-icon {
  position: absolute;
  cursor: pointer;
  height: 1.2rem !important;
  width: 1.2rem !important;
  font-size: 1.2rem;
  color: $sp-gray !important;
  margin-left: 0.2rem;
  z-index: 1;

  &::after {
    width: 10px;
    height: 10px;
    content: '';
    position: absolute;
    background: $black-font-color;
    top: 4px;
    left: 5px;
    border-radius: 50%;
    z-index: -1;
  }
}

/* CUSTOM SCROLLBAR */
.custom-scrollbar {
  &::-webkit-scrollbar, .chat__rich-textarea::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb, .chat__rich-textarea::-webkit-scrollbar-thumb {
    background: #CFD8DC;
  }

  &::-webkit-scrollbar-thumb:hover, .chat__rich-textarea::-webkit-scrollbar-thumb:hover {
    background: #B0BEC5;
  }
}

.highlighted-text {
  background: var(--highlight-color);
}

.pre-text {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  word-break: break-word;
  resize: none;
  box-sizing: border-box;
  width: 100%;
}

.tabs-nav-block {
  margin-top: 1.2rem;
}

.bg-grey {
  background-color: $sp-gray-bg !important;
}

.primary-shadow {
  box-shadow: $primary-shadow;
}

.blue-border {
  border: $border-blue;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.common-popup-title {
  font-size: 30px !important;
  line-height: 36px !important;
  margin-bottom: 30px !important;
  font-weight: 700 !important;
  color: $black-font-color;
}

.common-popup-sub-title {
  font-size: 20px !important;
  line-height: 26px !important;
  margin-bottom: 30px !important;
  font-weight: 700 !important;
  color: $black-font-color;
}

.search-block {
  width: 100%;
}

.word-break {
  word-break: break-word;
}

.divider {
  background-color: $sp-gray-bg;
  height: 2px;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 40px;
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}
