@import 'abstract/variables';

.form {
  padding-bottom: 1rem;

  &-label {
    display: block;
    margin-bottom: 0.5rem;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
  }
}

.mat-mdc-form-field {
  color: $sp-gray;
  margin-bottom: 23px !important;

  input {
    &:disabled {
      color: $sp-gray-disabled;
    }
  }
}

.calc-form, .consult {
  & mat-checkbox {
    display: inline-flex;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }

  .mat-mdc-form-field:not(.mat-paginator-page-size-select), mat-form-field:not(.mat-paginator-page-size-select) {
    width: 100%;
  }
}

.no-label {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    border-top: 0.3em solid transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow-wrapper {
    transform: translateY(0%) !important;
  }
}

.content-editable-input {
  text-align: left;
  min-height: 100px;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-line;
  word-break: break-word;
  word-wrap: normal;

  &:focus {
    outline: none;
  }
}

textarea.mat-mdc-input-element {
  padding: 0;
  color: $black-font-color;
}

.mat-placeholder-required {
  color: var(--red);
}

.mat-mdc-form-field.float-right-error {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-subscript-wrapper {
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    overflow: inherit;
  }
}

.form-field-title {
  margin: 0 !important;
  padding: 0.8rem 0 !important;
  font: 400 14px/20px !important;
  font-weight: 700;
  text-decoration: underline;
}

.inline-form-group {
  position: relative;
  display: inline-flex;
  align-items: baseline;
  flex-flow: row nowrap;
  justify-content: stretch;
  width: 100%;
  line-height: 1.125;
  min-height: 62px;
  margin-bottom: 5px;
  font-size: 16px;

  &.align-start {
    align-items: flex-start;
  }

  & > label, .label, & > .label mat-label {
    width: 215px;
    padding-right: 8px;
    white-space: pre-wrap;
    margin-top: 13px;
    line-height: 1.25;
    font-weight: 700 !important;
  }

  &__data, &__input, .row {
    flex: 1;
    display: flex;
    min-height: 62px;
    align-items: center;
  }

  .row {
    flex: 1;
  }

  p {
    margin: 0 !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-infix {
    width: auto;
  }

  @media (max-width: 544px) {
    display: inline-flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: auto;
    margin-bottom: 10px;

    & > label, .label {
      width: auto;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 10px;
    }

    &__data, &__input, & > .row {
      flex: 1;
      min-height: auto;
      flex-flow: column nowrap;
      align-items: flex-start;
    }
  }
}

.required-label {
  display: inline-flex;
  white-space: nowrap;

  &::after {
    content: '*';
    display: inline-flex;
    color: var(--red);
    margin-left: 0.2rem;
    font-weight: normal;
    text-decoration: none;
  }
}

sp-checkbox-group {
  .mat-mdc-checkbox {
    margin-bottom: 0.5rem;
    white-space: pre-wrap;
  }
}

.mat-mdc-form-field-error {
  color: $red !important;
}

.mat-mdc-radio-group {
  position: relative;

  .mat-mdc-radio-button {
    margin-bottom: 0.5rem;
    white-space: pre-wrap;
  }

  .mat-mdc-form-field-error {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

.readonly-form {
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-disabled .mat-checkbox-label {
    color: inherit;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
  .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: #3f51b5;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field-underline,
.mat-form-field-underline {
  height: 2px !important;
  background-color: $gray;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-focused .mat-form-field-ripple {
  background-color: $light-blue;
}

.mat-mdc-form-field {
  .mat-icon {
    min-width: 24px;
    color: $sp-gray;
  }
}

.mat-form-field-invalid .mat-mdc-input-element {
  color: $red;
}

.mat-mdc-form-field.mat-form-field-invalid .mat-form-field-ripple, .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: $red;
}

.mat-mdc-form-field.mat-form-field-invalid.mat-form-field-label.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after, .mat-form-field.mat-form-field-invalid .mat-form-field-label, .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent, .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: $red;
}

input.mat-mdc-input-element {
  //padding-bottom: 4px;
  color: $black-font-color !important;
  font-size: var(--font-size-base) !important;
  padding-bottom: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-color: $middle-gray !important;
}

//.mat-mdc-select-panel {
//  background: $extra-light-blue;
//}

.mat-mdc-select-panel:not([class*='mat-elevation-z']) {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 0.2), 0 4px 5px 0 rgb(0 0 0 / 0.14), 0 1px 10px 0 rgb(0 0 0 / 0.12);
  background: $sp-gray-bg;
}

.mat-mdc-select-panel .mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
  background: $light-blue !important;
}

//div.mat-mdc-select-panel {
//  min-width: 280px;
//}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $black-font-color !important;
}

.mat-mdc-paginator-container {
  .mat-mdc-icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $black-font-color;

    &:not(last-child) {
      margin-right: 30px;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-trigger {
  padding-bottom: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: $light-blue;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-disabled .mat-select-trigger {
  color: $black-font-color;
  opacity: 0.5;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-select-value-text {
  color: $black-font-color;
}

.disabled-form-control {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-mdc-form-field.mat-focused .mat-form-field-ripple {
    background-color: $middle-gray !important;
  }

  input {
    color: $middle-gray !important;
  }
}

.mat-mdc-form-field-error-wrapper,
.mdc-text-field {
  padding: 0 !important;
}

input.mat-mdc-input-element {
  padding-bottom: 4px !important;
  color: $gray;
  font-size: var(--font-size-base);
}

input.mat-mdc-input-element::placeholder {
  color: $sp-gray !important;
}

.mat-mdc-form-field-error {
  font-size: 12px !important;
}

.mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 0 !important;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: $red !important;
}

.mdc-text-field--invalid .mdc-text-field__input {
  color: $red !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: $sp-gray !important;
  border: 1px solid;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: $red !important;
  border: 1px solid;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: $light-blue !important;
  border: 1px solid;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  left: auto;
  right: auto;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 26px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 18px;
  padding-bottom: 0;
}

.mat-mdc-form-field-infix {
  flex: auto;
  min-width: 0;
  width: 180px;
  position: relative;
  box-sizing: border-box;
}

.mat-mdc-form-field-infix {
  //min-height: 32px;
}

.mat-mdc-form-field-flex {
  display: inline-flex;
  align-items: baseline;
  box-sizing: border-box;
  width: 100%;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  padding-bottom: 0 !important;
  margin: 0;
}

.mat-mdc-select-min-line {
  color: $black-font-color !important;
}

.mat-mdc-select-placeholder {
  color: $gray !important;
}

.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 0 !important;
}

.mdc-text-field__input {
  margin: 0;
}


.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  color: $red;
}

.mat-mdc-form-field-icon-suffix {
  //height: 20px !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  color: $black-font-color !important;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-floating-label,
.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
  color: $table-border-light-gray !important;
}

.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: $table-border-light-gray !important;
  border-width: 2px;
}

mat-hint {
  font-size: 12px !important;
  line-height: normal;
}

.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  margin-left: 1px;
}

.mat-mdc-select-value {
  color: $black-font-color;
}

.mat-mdc-select-panel {
  padding: 0 !important;
}
