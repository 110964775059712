@import 'abstract/variables';

/* TODO(mdc-migration): The following rule targets internal classes of autocomplete that may no longer apply for the MDC version. */
.angular-editor-textarea,
.custom-scrollbar-container,
.mat-mdc-table,
.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel,
.mat-mdc-dialog-content {
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $sp-gray;
  }

  &::-webkit-scrollbar {
    appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
}
