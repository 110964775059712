@import 'abstract/variables';
@import 'abstract/mixins';

.btn-primary-accept {
  @include btn($light-blue);
}

.btn-white {
  @include btn($white);

  border: 1px solid rgb(0 0 0 / 0.42) !important;
}

.btn-black {
  @include btn($black);

  border: 1px solid rgb(0 0 0 / 0.42) !important;
}

.btn-purple {
  @include btn(
    $white,
    2px solid $light-blue,
    $light-blue,
    $black-font-color,
    $white,
    2px solid $sp-gray,
    $sp-gray);
}

.btn-light-purple {
  @include btn($light-purple);
}

.btn-default {
  border: 1px solid $dark-gray !important;
}

.mat-mdc-button.mat-primary[disabled], .mat-button.mat-accent[disabled], .mat-button.mat-warn[disabled], .mat-button[disabled][disabled], .mat-mdc-icon-button.mat-primary[disabled], .mat-icon-button.mat-accent[disabled], .mat-icon-button.mat-warn[disabled], .mat-icon-button[disabled][disabled], .mat-mdc-outlined-button.mat-primary[disabled], .mat-stroked-button.mat-accent[disabled], .mat-stroked-button.mat-warn[disabled], .mat-stroked-button[disabled][disabled] {
  color: rgb(0 0 0 / 0.26) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-toggle:active, .mat-button-toggle:focus {
  outline: none;
}

.lot-viewer-wrapper, .patient-insertion-side, .patient-insertion-location {
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  & .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px;
    padding: 0 8px;
  }
}

.insertion-side, .insertion-location {
  .mat-button-toggle .mat-pseudo-checkbox {
    display: none;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  & .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 5px 0;
    font-weight: 400;
    font-size: 16px;

    @media (min-width: $exl) {
      padding: 10px 0;
      font-size: 20px;
      line-height: 24px;
    }
  }

  button[aria-pressed='true'] {
    background: $light-blue;
    color: var(--white);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  mat-button-toggle {
    background: $white !important;
    width: 100%;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  .mat-button-toggle-group-appearance-standard {
    border-radius: 6px !important;
    width: 100%;
    border: 1px solid $light-blue;
  }
}

.btn-width-125 {
  width: 125px;
}

button {
  flex-shrink: 0;
}

.link-blue {
  color: $light-blue;

  &:hover {
    color: $sp-dark-light-blue;
    transition: $btn-hover-animation;
    text-decoration: none;
  }
}

.link-black {
  color: $black-font-color;

  &:hover {
    opacity: 0.8;
  }
}

.primary-accept-hover {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.common-btn,
.icon-btn {
  font-family: var(--font-family);
  background: transparent;
  cursor: pointer;
  border: none;
}

.icon-btn {
  &:hover {
    opacity: 0.8;
  }
}

.mdc-radio__outer-circle {
  border-width: 1px !important;
  border-color: $gray !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: $light-blue !important;
}

.mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.6) !important;
  background: $light-blue;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-primary.mat-radio-checked .mat-radio-inner-circle {
  background-color: $light-blue;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 10px 10px 10px 0 !important;
}

.mdc-radio,
.mdc-radio__background {
  width: 24px !important;
  height: 24px !important;
}

.btn-add-new-patient {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
}

.add-new-patient-btn {
  margin-bottom: 10px;
}

.common-table-action-btn {
  padding: 0;
  cursor: pointer;
  border: none;
  background: transparent;

  .mat-icon {
    color: $white;

    &:hover {
      color: $white;
      opacity: 0.8;
    }
  }
}

/* stylelint-disable no-descending-specificity */
.btn-purple-bg-round {
  background: $light-blue;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .mat-icon {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.link-btn {
  text-decoration: none;
}

.negative-img-m {
  margin: -5px;
}

.mat-icon-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-for-btn {
  color: $white !important;
  font-size: 20px !important;
  height: 20px !important;
  width: 20px !important;
  display: block !important;
}

.acknowledge-btn {
  padding: 10px 6px 10px 15px !important;

  span {
    margin-right: 15px;
  }

  div {
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin: -5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.mat-mdc-icon-button {
  height: 40px !important;
  width: 40px !important;
  padding: 0 !important;
}

.mat-mdc-icon-button[disabled] {
  color: #00000042 !important;
}

.mat-button-toggle-button {
  height: 100%;
}
