@import 'abstract/variables';

.mat-autocomplete-panel:not([class*='mat-elevation-z']) {
  box-shadow: $primary-shadow;
}

.mat-mdc-option:hover:not(.mat-option-disabled) {
  background-color: $sp-gray-disabled !important;
  color: $light-blue;
}

.mat-mdc-option.mat-active {
  background-color: $sp-gray-disabled !important;
  color: $light-blue;
}

.mat-mdc-option {
  line-height: normal !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: $sp-gray-disabled !important;
}

//.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
//  color: $black-font-color !important;
//}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: $sp-gray-disabled !important;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.mat-mdc-autocomplete-panel {
  background-color: $sp-gray-bg !important;
  color: $black-font-color !important;
}
